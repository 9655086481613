<template>
    <b-card :title="`${$t('create_role')}`">
        <form @submit.prevent="storeRole" @keydown="form.onKeydown($event)" class="edit-form" enctype="multipart/form-data">
            <b-row>
                <b-col sm="6">
                    <div class="form-group">
                        <label for="name">{{ $t('name') }}<span class="text-danger">*</span></label>
                        <input type="text" name="name" class="form-control" v-model="form.name" :class="{ 'is-invalid': form.errors.has('name') }">
                        <has-error :form="form" field="name" />
                    </div>
                </b-col>
                <b-col sm="6">
                    <b-card :title="$t('permissions')">
                        <div class="custom-control custom-checkbox" v-for="(item, index) in permissions" :key="index">
                            <input
                            type="checkbox"
                            class="custom-control-input"
                            :id="`customCheck_${index}`"
                            v-model="form.permissions"
                            :value="item.id">
                            <label class="custom-control-label" :for="`customCheck_${index}`">{{ item.name }}</label>
                        </div>
                    </b-card>
                </b-col>
            </b-row>
            <button type="submit" class="btn btn-success" :disabled="form.busy">{{ $t('add') }}</button>
        </form>
    </b-card>
</template>
<script>
import Form from 'vform'
import axios from 'axios'

export default {
    middleware: 'auth',
    layout: 'default',
    metaInfo () {
        return { title: this.$t('edit_role') }
    },
    data: () => ({
        form: new Form({
            name: '',
            permissions: []
        }),
        permissions: null,
        role: null
    }),
    mounted () {
        this.getPermissions()
    },
    methods: {
        async storeRole () {
            // add new user
            this.form.busy = true
            const apiUrl = process.env.VUE_APP_ENDPOINT_V1
            await this.form.post(apiUrl + 'roles').then(resp => {
                this.$router.push({ name: 'roles' })
            }).catch(error => {
                this.form.errors.set(error.response.data.error.errors)
            })
        },
        async getPermissions () {
            const apiUrl = process.env.VUE_APP_ENDPOINT_V1
            const { data } = await axios.get(apiUrl + 'permissions/list')
            this.permissions = data.data
        }
    }
}
</script>
